<template>
  <div>
    <list
      ref="refProfileHabilityList"
      sort-by="PerfilHabilidadId"
      :refetch-records="fetchProfilesHability"
      refetch-records-name="perfilesHabilidades"
      :actions="actions"
      key-field="perfilHabilidadId"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :delete-method="deleteProfileHability"
      :filters.sync="filters"
    />>
  </div>
</template>
<script>

import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import List from '@/components/List.vue'
import humanResources from '@/services/humanresources.service'

export default {
  components: {
    List,
  },
  setup() {
    /* Refs */
    const refProfileHabilityList = ref(null)
    /* Services */
    const {
      fetchProfilesHability,
      deleteProfileHability,
    } = humanResources()
    /* Data */
    const tableColumns = [
      {
        label: i18n.t('humanResources.profileHability.columns.name'),
        key: 'perfil.nombre',
      },
      {
        label: i18n.t('humanResources.profileHability.columns.hability'),
        key: 'habilidad.nombre',
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
      },
    ]
    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('Lists.Edit'),
        aclAction: 'create',
        aclResource: 'Perfil',
        routeName: 'apps-human-resources-profile-hability-edit',
        params: ['perfilHabilidadId'],
        icon: 'CodepenIcon',
      },
      {
        name: 'delete',
        label: i18n.t('Lists.Delete'),
        aclAction: 'delete',
        aclResource: 'Perfil',
        params: ['perfilHabilidadId'],
        icon: 'TrashIcon',
      },
    ])
    const actions = ref([
      {
        routeName: 'apps-human-resources-profile-hability-add',
        label: i18n.t('Routes.ProfileHabilityAdd'),
        aclAction: 'create',
        aclResource: 'Perfil',
        icon: 'PlusCircleIcon',
      },
    ])
    const filters = ref([])
    return {
      refProfileHabilityList,
      tableColumns,
      tableActions,
      actions,
      filters,
      fetchProfilesHability,
      deleteProfileHability,
    }
  },
}
</script>
